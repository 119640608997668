import React,{useEffect, useRef, useState} from "react";
import { getLineType } from "../../service";
import './FormContainer.scss'
import { useLoading } from "../../../../../Shared/cache/context/LoadingProvider";
import ToastMsg from "../../../shared/components/ToastMsg/ToastMsg";
import { eventEmitter } from "../../../../../Shared/service/google-analytics.service";

const FormContainer =({formInput,
   handleChange,
   crossClick,
   sendDataToParent,
   maxAmount,
   minAmount,
   minError,
   maxError,
   showBillSummary=false,
   showBundles ,
   sendOtpEvent,
   showAmountField=true,
   isBroadband=false,
   showNoteForAmount=false,
   sendLineTypeData=undefined,
   billInfo={},
   msidnError
  })=>{

  const [isShowFetchBillMsg,setShowFetchBillMsg]=useState(false);
  // const [activeClass,setactiveClass]=useState(false);
  // const [activeAmount,setactiveAmount]=useState(false);
  const { showLoader, hideLoader } = useLoading();
  const [toastMsg ,setToastMsg]=useState(null);
  const amountval = useRef(null);

  useEffect( () => {
    if(formInput?.msisdn){
      checkMsisdn(formInput?.msisdn)
    }
  },[formInput?.msisdn])
  
  const checkMsisdn=(val,event)=>{
    isLineTypeSuccess(false)
    document.getElementById('msg').innerHTML='';
    if (val) {
      // setactiveClass(true)
      val = val.replace(/[^0-9]/g, "");
      formInput.msisdn = val;
      if(event){
        event.target.value = val;
      }
      if (val?.length == process.env.REACT_APP_NUMBER_MAX_LENGTH) {
        validateMsisdn();
      }
    }
    // else {
    //   setactiveClass(false)
    // }
    formInput.amount=''
 }

  const validateMsisdn=()=>{
    let requestData = {
      msisdn: process.env.REACT_APP_OPCO.toLowerCase() == "ga" ? "0" + formInput.msisdn :formInput.msisdn,
      checkIfBroadband: isBroadband ? true : false
    };
       showLoader();
       getLineType(requestData).then(
       (res) => {
        res = res?.data;
        hideLoader();
        if(res?.status?.toLowerCase()=="success" || res?.status?.toLowerCase()==200 ){
          // if(showBundles=="true" && res?.result=="POSTPAID"){
            isLineTypeSuccess(true);
            if(sendLineTypeData){
              sendLineTypeData(res);
            }
          // }
          // if(showBundles!="true" && res?.result!="POSTPAID" ){
          //   isLineTypeSuccess(true);
          // };
          setShowFetchBillMsg(true);
          eventEmitter('ORP',' Validate Msisdn Success','click',' Validate Msisdn Success',null);
        }else{
          document.getElementById('msg').innerHTML=res?.message;
          eventEmitter('ORP',' Validate Msisdn Failed','click',res?.message,null);
        }
      })
    .catch((error)=>{
      hideLoader();
      showToastMsg('Error',error?.error?.message ||error?.message|| "Something Went Wrong!")
      eventEmitter('ORP','Validate Msisdn Failed','click',error?.error?.statusCode?.toString(),null);

    })
  
  }
  const isLineTypeSuccess=(res)=>{
    sendDataToParent(res)
  }

  const getAmountval = (val,event)=> {
      val = val.replace(/[^0-9]/g, "");
      formInput.amount = val;
      event.target.value = val;
    // if (val) { setactiveAmount(true)}
    // else{setactiveAmount(false)}
  }

  const showToastMsg= (type,message)=>{
    setToastMsg({type,message});
    setTimeout(()=>setToastMsg(null),3000);
  }

  
    return(
       <>
       
        <form autocomplete="off">
          <div className={`formGroup ${formInput.msisdn?'active':''}`} >
            
            <span>+{process.env.REACT_APP_COUNTRY_CODE}</span>
            <input 
             
             className="formControl" type="text" name="msisdn" value={formInput.msisdn} onChange={handleChange} onInput={(event)=>checkMsisdn(event.target.value,event)} maxLength={process.env.REACT_APP_NUMBER_MAX_LENGTH}/>
            <label >Mobile Number</label>
            {formInput.msisdn>0 && <img
              className="form-close"
              src={'assets/icons/close-thin-white.svg'}
              alt=""
              onClick={crossClick}
            />}
             {msidnError &&  <p>{msidnError}</p>}
             <div
              className="error-validation">
             <p className="errorMsg" id="msg"></p>
            </div>
          </div>
          {showAmountField &&
         <div className={`formGroup ${formInput.amount?'active':''}`} >
            <span>{process.env.REACT_APP_CURRENCY}</span>
            <input  className="formControl"  type="tel"  name="amount"   onInput={(event)=>getAmountval(event.target.value,event)}  value={formInput.amount} onChange={handleChange} min={minAmount} max={maxAmount} />
            <label>Enter the amount</label>
            {minError &&  <p>{minError} {process.env.REACT_APP_CURRENCY} {minAmount}</p>}
            {maxError &&  <p>{maxError} {process.env.REACT_APP_CURRENCY} {maxAmount}</p>}
            
            {/* <button onClick={()=>onProccedRecharge()}></button> */}
          </div>
          }
        </form>
        {toastMsg && <ToastMsg message={toastMsg.message} type={toastMsg.type} onClose={()=> setToastMsg(null)}></ToastMsg>}
        </>
    )
    }
   
    export default FormContainer