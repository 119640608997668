import React, { useState } from "react";
import { getOtp, resendOtp, verifyOtp, validateMsidnViaAccount, getBillAmount } from "../../../shared/service";
import './OtpModal.scss'
import { useLoading } from "../../../../../Shared/cache/context/LoadingProvider";
import ToastMsg from "../../../shared/components/ToastMsg/ToastMsg";
import { eventEmitter } from "../../../../../Shared/service/google-analytics.service";

const OtpModal = ({ msisdn, toggleModalEvent, billSummaryEvent,sendBillInfo }) => {
    const [showOtpModal, setShowOtpModal] = useState(true);
    const [showAccountModal, setShowAccountModal] = useState(false);
    const [isOtpSent, setIsotpSent] = useState(false);
    const [otpRefId, setOtpRefId] = useState('');
    const [otp, setOtp] = useState();
    const [accountNumber, setAccountNumber] = useState('');
    const [unbilledAmount, setUnbilledAmout] = useState('');
    const [OutstandingAmount,setOutstandingAmount]=useState('');
    const [otpErrorMsg,setOtpErrorMsg]=useState('');
    const [accountErrorMsg,setAccountErrorMsgs]=useState('');
    const { showLoader, hideLoader } = useLoading();
    const [toastMsg ,setToastMsg]=useState(null);


    const sendOtp = (event) => {
        event.preventDefault();

        let postRequestData = {
            // typeOfNumber: "PREP",
            text: msisdn,
            text1: null,
            otpAction: "INVOICE"
        };
        let postRequest = {
            data: postRequestData,
        };

        showLoader();
        getOtp(postRequest).then((res) => {
            let data;
            res = res?.data;
            hideLoader();
            if (
                res &&
                (res?.status == 200 || res?.statusCode == 200 || res?.status?.toLowerCase() == "success")
            ) { 
                setOtpErrorMsg('')
                setIsotpSent(true);
                setOtpRefId(res.result.otpId);
                showToastMsg('Success',res?.message || res?.result.message )
                eventEmitter('ORP','Postpaid Bill Payment Get Otp Success','click','Postpaid Recharge Get Otp Success',null);
                return
            } else {
                 setOtpErrorMsg(res?.message|| "something went wrong!")
                 showToastMsg('Error',res?.message || "Something Went Wrong!")
                 eventEmitter('ORP','Postpaid Bill Payment Get Otp Failed','click',res?.message,null);

            }
        }).catch(error => {
            hideLoader();
            console.log('error from get otp', error)
            showToastMsg('Error',error?.error?.message ||error?.message|| "Something Went Wrong!")
            eventEmitter('ORP','Postpaid Bill Payment Get Otp Failed','click',error?.error?.statusCode?.toString(),null);

        }
        )
    }

    const resendOTP = (event) => {
        event.preventDefault();
        let postRequestData = {
            otpId: otpRefId
        };
        let postRequest = {
            data: postRequestData,
        };
        showLoader();
        resendOtp(postRequest).then(
            (res) => {
                //   this.loaderService.stopLoading();
                let data;
                res = res?.data;
                hideLoader();
                if (
                    res &&
                    (res?.status == 200 || res?.statusCode == 200 || res?.status?.toLowerCase() == "success")
                ) {
                    setOtpErrorMsg('')
                    setOtpRefId(res?.result?.otpId);
                    showToastMsg('Success',res?.message || res?.result.message )
                    eventEmitter('ORP','Postpaid Bill Payment Resend Otp Success','click','Postpaid Recharge Resend Otp Success',null);
                    return;
                } else {

                    setOtpErrorMsg(res.message || "something went wrong!")
                    eventEmitter('ORP','Postpaid Bill Payment Resend Otp Failed','click',res?.message,null);

                }
            },
            (error) => {
                //   this.loaderService.stopLoading();
                hideLoader();
                let toastData = {
                    msg: error.error.message || "something went wrong!",
                };
                eventEmitter('ORP','Postpaid Bill Payment Resend Otp Failed','click',error?.error?.statusCode?.toString(),null);

            }
        );
    }

    const verifyOTP = (event) => {
        event.preventDefault();
        let postRequestData = {
            // typeOfNumber: "PREP",
            otp: otp,
            otpId: otpRefId
        };
        let postRequest = {
            data: postRequestData,
        };
        showLoader();
        verifyOtp(postRequest).then(
            (res) => {
                hideLoader();
                //   this.loaderService.stopLoading();
                res = res?.data;
                console.log('resp otp ',res)
                if (
                    res &&
                    (res?.result?.status == 200 || res?.statusCode == 200 || res?.status?.toLowerCase() == "success")
                ) {
                    setOtpErrorMsg('')
                    fetchBillAmount(event);
                    eventEmitter('ORP', 'Postpaid Bill Payment Verify Otp Success', 'click', 'Postpaid Recharge Verify Otp Success', null);
                    return
                } else {
                    setOtpErrorMsg(res?.result?.message || "something went wrong!");
                    eventEmitter('ORP', 'Postpaid Bill Payment Verify Otp Failed', 'click', res?.message, null);

                }
            },
            (error) => {
                hideLoader();
                setOtpErrorMsg(error.error.message || "something went wrong!");
                eventEmitter('ORP', 'Postpaid Bill Payment Verify Otp Failed', 'click', error?.error?.statusCode?.toString(), null);

            }
        );
    }

    const openAccountModal = () => {
        setShowOtpModal(false)
        setShowAccountModal(true);
        toggleModalEvent(true);
    }

    const isAccountLengthInValid = () => {
        return (!accountNumber || accountNumber?.length != process.env.REACT_APP_ACCOUNT_NUMBER_LENGTH)
    }

    const fetchViaAccount = (event) => {
        event.preventDefault();
        let postRequestData = {
            msisdn: msisdn,
            accountNo: accountNumber
        };
        let postRequest = {
            data: postRequestData,
        };
        showLoader();
        validateMsidnViaAccount(postRequest).then(
            (res) => {
                hideLoader()
                // this.loaderService.stopLoading();
                let response = res?.data;
                if (
                    response && (response?.statusCode == 200 || response?.status?.toLowerCase() == "success")
                ) {
                    setAccountErrorMsgs('')
                    fetchBillAmount(event);
                    // setAccountNumber(false);
                      eventEmitter('ORP','Postpaid Bill Payment Account Number validate Success','click','Postpaid Recharge Account Number validate Success',null);
                    return
                }
                else if (response?.statusCode && (response?.statusCode === 60119 || response?.statusCode === 60005)) {
                    // this.accountMsisdnErrorFlag=true;
                    // this.accountMsisdnErrorMsg=response?.message;
                    setAccountErrorMsgs(response?.message || "something went wrong!" )
                    let toastData = { msg: response?.message || "something went wrong!" };
                    // this._toaster.Error('Error',toastData?.msg);
                    eventEmitter('ORP','Postpaid Bill Payment Account Number validate Failed','click',response?.message,null);
                }
                else {
                    setAccountErrorMsgs(response?.message || "something went wrong!" )
                    let toastData = { msg: response?.message || "something went wrong!" };
                    // this._toaster.Error('Error',toastData.msg);
                    eventEmitter('ORP','Postpaid Bill Payment Account Number validate Failed','click',response?.message,null);
                }
            },
            (error) => {
                hideLoader();
                // this.loaderService.stopLoading();
                let toastData = {
                    msg: error?.error?.message || "something went wrong!",
                };
                setAccountErrorMsgs(error?.error?.message || "something went wrong!" )
                //   this._toaster.Error('Error',toastData.msg);
                eventEmitter('ORP','Postpaid Bill Payment Account Number validate Failed','click',error?.error?.statusCode?.toString(),null);

            }
        );
    }

    const fetchBillAmount = (event) => {
        event.preventDefault();
        // this.loaderService.startLoading();

        let postRequestData = {
            msisdn: msisdn,
            otpId: otpRefId
        };

        let postRequest = {
            data: postRequestData
        };

        getBillAmount(postRequest).then(
            (res) => {
                // this.loaderService.stopLoading();
                res = res?.data;
                if (
                    res &&
                    (res.statusCode == 200 || res.status.toLowerCase() == "success")
                ) {
                    eventEmitter('ORP','Postpaid Bill Payment Fetch Bill Success','click','Postpaid Recharge Fetch Bill Success',null);
                    setShowAccountModal(false);
                    setShowOtpModal(false);
                    
                    setUnbilledAmout(`${process.env.REACT_APP_CURRENCY} ${res?.result?.totalOutstanding}`);
                    //   this.user.amount = res?.result?.totalOutstanding;
                    setOutstandingAmount( res?.result?.totalOutstanding)
                    billSummaryEvent(true);
                    sendBillInfo(res?.result)
                    return
                } else {
                   showToastMsg('Error', res.message || 'Something went wrong!')
                    eventEmitter('ORP', 'Postpaid Bill Payment Fetch Bill Failed', 'click', res?.message, null);

                }
            },
            (error) => {
                // this.loaderService.stopLoading();
                let toastData = {
                    msg: error.error.message || "something went wrong!",
                };
                // this._toaster.Error(
                //     'Error', toastData.msg);
                eventEmitter('ORP', 'Postpaid Bill Payment Fetch Bill Failed', 'click', error?.error?.statusCode?.toString(), null);

            }
        );
    }

   
    const showToastMsg= (type,message)=>{
        setToastMsg({type,message});
        setTimeout(()=>setToastMsg(null),3000);
      }

    return (<>
        {showOtpModal && <>
            <div id="myModal" className=" pop-up-outer adv-modal modal fade in" role="dialog">
                <div className="otp-modal">
                    {/* <!-- Modal content--> */}
                    <div className="otp-content">
                        <div className="otp-header">
                            <h3>Verify your account</h3>
                            <p>OTP will be sent to your mobile No.</p>
                            <button type="button" className="close" data-dismiss="modal" onClick={() => { setShowOtpModal(false); toggleModalEvent() }}>×</button>
                            {/* <!--         <h4 className="modal-title">Modal Header</h4> --> */}
                        </div>
                        <div className="modal-body ">
                          
                            <form>
                                <div className="form-grp"> 
                                <input type="text"
                                    name="sendOtp"
                                    value={msisdn}
                                    maxLength={process.env.REACT_APP_NUMBER_MAX_LENGTH}
                                    placeholder="Mobile Number"
                                    readOnly 
                                    className="form-control"
                                    />
                                {!isOtpSent && <button className="get_otp" onClick={sendOtp}>Get OTP</button>}
                                
                                </div>
                               
                              
                                {isOtpSent && <>
                                    <div className="form-grp"> 
                                    <input
                                        type="password"
                                        className="form-control" maxLength={6}
                                        ength="6"
                                        name="otp-number"
                                        placeholder="Enter OTP"
                                        
                                        onInput={(event) => setOtp(event?.target?.value)}
                                    />
                                   </div>
                                   {/* <p className="otp_err">  {otpErrorMsg}</p> */}
                                    <div className="receive_otp">Didn't receive OTP? <span onClick={resendOTP}>RESEND</span></div>
                                   <div className="accountModal">
                                           <button className="fetch_account" onClick={openAccountModal}>Still Facing Problem? <span>Fetch Via Account Number</span></button>
                                           
                                   </div>
                                </>}
                                 <button className="get_outstanding"  disabled={!otp} onClick={verifyOTP}>Get Outstanding</button>
                                <span></span>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>}
        {showAccountModal && <>
            <div id="myModal" className="pop-up-outer adv-modal modal fade in" role="dialog">
                <div className="otp-modal">
                    {/* <!-- Modal content--> */}
                    <div className="otp-content">
                        <div className="otp-header">
                            <h3>Please enter your account number</h3>
                            <p>{`Enter your ${process.env.REACT_APP_ACCOUNT_NUMBER_LENGTH} digit account number`} </p>
                            <button type="button" className="close" data-dismiss="modal" onClick={() => { setShowAccountModal(false); toggleModalEvent() }}>×</button>
                            {/* <!--         <h4 className="modal-title">Modal Header</h4> --> */}
                        </div>
                        <div className="modal-body ">
                            <div></div>
                            <input
                               className="form-control"
                                name="accountNumber"
                                maxLength="10"
                                placeholder="Account Number"
                                onInput={(event) => setAccountNumber(event.target.value)}
                            />
                          <p className="Otp_err error">  {accountErrorMsg} </p>
                        </div>
                        {/* add css disable calss using method isAccountLengthInValid */}
                        <div className="accountModal accountModal_account">
                        <button className="get_outstanding" onClick={fetchViaAccount} >Get Outstanding</button>
                    </div>  </div>
                </div>
            </div>
        </>

        }
          {toastMsg && <ToastMsg message={toastMsg.message} type={toastMsg.type} onClose={()=> setToastMsg(null)}></ToastMsg>}

    </>)
}

export default OtpModal;